<template>
  <div class="project-issues">

     <columns class="mb-5 is-centered">
      <column class="is-half">
          <div class="field is-grouped">
            <div class="control">
              <datepicker v-model="start" format="yyyy-MM-d" class="input mr-2" placeholder="Start date"  />
            </div>
            <div class="control">
              <datepicker v-model="end" format="yyyy-MM-d" class="input mr-2" placeholder="End date" />
            </div>
            <div class="control">
              <button class="button is-link" @click="hi">Filter</button>
            </div>

            <div class="control">
              <button class="button is-info is-light" @click.prevent="exporToFile">Export CSV</button>
            </div>
          </div>
      </column>

      <column class="is-half">

          <new-issue-button class="buttons is-right" :fullPath="fullPath" />

      </column>

    </columns>

    <div v-if="loading">
      <loading-state />
    </div>

    <div v-else class="is-relative box is-shadowless">
        <div class="block has-background-white">
          <div class="scroll">
            <v-grid
              theme="material"
              :source="cloneIssues"
              :columns="columns"
              exporting
              readonly
              filter
              range
            />
          </div>
        </div>

    </div>


  </div>
</template>

<script>
import { defineAsyncComponent, reactive, toRefs, ref, readonly } from 'vue'
import moment from "moment"
import VGrid from "@revolist/vue3-datagrid"
import { getProjectIssues } from '@/use/useProject'
import Datepicker from 'vue3-datepicker'


const columns = [

  {
    prop: "title",
    name: "Title",

    size: 400
  },
  {
    prop: "spent",
    name: "Spent",
  },
  {
    prop: "state",
    name: "State",
    sortable: true,
    order: 'asc',
  },
  {
    prop: "assignee",
    name: "Assignee",
    sortable: true,
    size: 150,
  },
  {
    prop: "cdate",
    name: "Created",
    // cellTemplate: VGridVueTemplate(CellCDate),
    sortable: true,
    columnType: 'date',

    size: 150,
  },
  {
    prop: "udate",
    name: "Updated",
    sortable: true,
    columnType: 'date',
    order: 'desc',
    size: 150,
  }
];


export default {
  props: {
    fullPath: {
      type: String,
      required: true
    },
  },

  components: {
    LoadingState: defineAsyncComponent(() => import('@/components/general/Loading')),
    Columns: defineAsyncComponent(() => import('@/components/ds/Columns.vue')),
    Column: defineAsyncComponent(() => import('@/components/ds/Column.vue')),
    NewIssueButton: defineAsyncComponent(() => import('@/components/issues/new/NewIssueButton')),
    Datepicker,
    VGrid,
  },

  setup (props) {

    const start = ref(null)
    const end = ref(null)

    const { loading, issues, refetchProject } = getProjectIssues(props.fullPath);

    const cloneIssues = readonly(issues)

    const state = reactive({
      columns: columns
    })

    const exporToFile = () => {

      const name = 'report_project' + '_' + Date.now().toString()

      const grid = document.querySelector('revo-grid');
          grid.getPlugins().then(plugins => {
          plugins.forEach(p => {
            if (p.exportFile) {
                const exportPlugin = p;
                exportPlugin.exportFile({  filename: name });
            }
            })
          });
    }

    const hi = () => {
      let _start = moment(start.value).format('yyyy-MM-DD')
      let _end = moment(end.value).format('yyyy-MM-DD')

      if( moment(_start).isBefore(_end) ) {
        refetchProject(_start, _end)
      } else {
        alert("invalid date!")
      }
    }



    return {
      loading,
      cloneIssues,
      exporToFile,
      start,
      end,
      hi,
      ...toRefs(state)
    }
  }
}





</script>

<style lang="scss">
  revo-grid {
    height: 100%;

    .main-viewport {
      height: auto;
    }

    .header-wrapper {
      background: hsl(0, 0%, 96%);
      font-weight: bolder;
    }

  }

  .project-issues {
    min-height: 400px
  }
</style>
